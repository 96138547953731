import React from "react"
import styled from "styled-components"
import AwardItem from "./awardItem"

const Awards = ({ title, items }) => {
  return (
    <Container>
      {title ? <AwardTitle>{title.text}</AwardTitle> : null}
      <AwardsContainer>
        {items.map((award, i) => (
          <AwardItem key={i} {...award} />
        ))}
      </AwardsContainer>
    </Container>
  )
}

export default Awards

const Container = styled.div``

export const AwardTitle = styled.h2`
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-text);
  font-size: 28px;
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
`
const AwardsContainer = styled.div`
  padding-left: 120px;
  div:first-child {
    > :nth-child(2) {
      border-top: none;
      border-bottom: none;
    }
  }
  @media (max-width: 640px) {
    padding-left: 0;
  }
`
