import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const AwardItem = ({ award_giver, award_image, body }) => {
  const awards = body.map(award => {
    return { award_year: award.primary.award_year, items: award.items }
  })
  return (
    <Container>
      <ImageContainer>
        <Img
          fadeIn={true}
          fixed={award_image.fixed}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      </ImageContainer>
      <Wrapper>
        <Title>{award_giver.text}</Title>

        {awards.map((awardItem, i) => (
          <YearGroup key={i}>
            <Year>{awardItem.award_year}</Year>
            <AwardsGroup>
              {awardItem.items.map((item, i) => (
                <Award key={i}>{item.award_name.text}</Award>
              ))}
            </AwardsGroup>
          </YearGroup>
        ))}
      </Wrapper>
    </Container>
  )
}

export default AwardItem

const Container = styled.div`
  padding-bottom: 40px;
  justify-self: center;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 0 40px;
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    grid-gap: 0 24px;
  }
  @media (max-width: 640px) {
    text-align: center;
  }
`

const Wrapper = styled.div`
  padding-top: 56px;
  border-top: 1px solid #e0ded7;
  @media (max-width: 860px) {
    padding-top: 8px;
  }
  @media (max-width: 640px) {
    padding-top: 8px;
    border-top: none;
  }
`
const AwardsGroup = styled.div`
  display: grid;
  grid-gap: 8px 0;

  p:first-child {
    font-weight: bold;
    font-size: 18px;
  }
`
const YearGroup = styled.div`
  padding-top: 16px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 56px;
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-gap: 0 24px;
  }
`

const Title = styled.p`
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-text);
  @media (max-width: 640px) {
    text-align: center;
  }
`
const Year = styled.p`
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-text);
`

const Award = styled.p`
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  max-width: 500px;
  line-height: 24px;
`
const ImageContainer = styled.div`
  padding-top: 40px;
  justify-self: center;
  .gatsby-image-wrapper {
    max-height: 100px;
    max-width: 100px;
  }
  @media (max-width: 860px) {
    padding: 16px;
    margin: 0 auto;
  }
`
